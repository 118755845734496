import React, { useEffect, useState } from 'react';
import { get_sellers, search_seller, add_seller, get_users } from '../../../services/api/Leads';
import { Toggle_Stat, Filters_, Main_Plain, PageAlwaysVisible, PageFull, PageTitle, TD, TH, TR, Table, Slider_, Builder_, Grey_Link, Input_ } from 'monica-alexandria'
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { useNavigate, useParams } from 'react-router-dom';

const TeamPage = styled(PageFull)``

const SearchWrappper = styled.div`
	width: 100%;
`

const SellerRole = styled.div`
	padding: var(--smallPads);
	border-radius: .5rem;
	background: var(--main);
	color: var(--white);
	width: fit-content;

`

export default function TEAM() {
    const [sellers, setSellers] = useState([]);
	const [seller, setSeller] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [search] = useDebounce(searchTerm, 1000);

	const navigate = useNavigate();
	const {lang} = useParams();

	
	const handleSave = () => {

		add_seller(seller, seller?.roles?.includes('Merchant'))
		.then(res => { 
			setSellers(prevSellers => prevSellers.map(s => 
				s.id === seller.id ? { ...s, ...seller } : s
			));
	
		})
		.catch(err => {
			console.log(err)
		})

		setSeller(false);
	};


	useEffect(() => {

		if (search) {

			search_seller(sellers, search)
            .then(data => {
                setSellers(data)
            })
            .catch(err => {
                console.log(err)
            })
		}
		else {
			getUsers();
		}

	}, [search]);

    useEffect(() => {
		getUsers();
	}, []);


	const getUsers = () =>{
		get_users()
		.then(data => {
			setSellers(data)
		})
		.catch(err => {
			console.log(err)
		})
	}
	
	const handleMerchantRole = () =>{
		setSeller(prevSeller => {
			const hasMerchantRole = prevSeller.roles.includes("Merchant");

			const updatedRoles = hasMerchantRole 
				? prevSeller.roles.filter(role => role !== "Merchant")
				: [...prevSeller.roles, "Merchant"];

			return {
				...prevSeller,
				roles: updatedRoles
			};
		});

	}

	console.log('sellers', sellers);
	console.log('seller', seller);
    return (
        <TeamPage>
			{seller !== false ? (
				<Slider_  
					title={`Assign role to ${seller?.lastname} ${seller?.firstname}`} 
					onClick={handleSave} 
					icon="Check" 
					text="Save" 
					onClose={()=> setSeller(false)}
				>	
					<Builder_>
					<Toggle_Stat
						active={seller?.roles?.includes('Merchant')}
						onClick={()=>handleMerchantRole()}
						text="Seller"
					/>
					</Builder_>
				</Slider_>
			): null}
            <PageAlwaysVisible>
                <PageTitle>
					<Grey_Link iconLeft='Back' onClick={()=> navigate(`/${lang}/`)}/> 
                    <h1>Team Members</h1>
                </PageTitle>
                <Filters_>
                    {/* <Search
						type="text"
						placeholder="Search"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/> */}
					<SearchWrappper>
						<Input_
							placeholder="Search"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</SearchWrappper>
				</Filters_>
                <Table>
					<thead>
						<TR>
							<TH>Firstname</TH>
							<TH>Lastname</TH>
							<TH>Email</TH>
							<TH>Role</TH>
							<TH>Actions</TH>
						</TR>
					</thead>
					<tbody>
						{sellers?.length > 0 && sellers?.map((seller, i) => (
							<TR key={i}>
								
								<TD>{seller?.firstname}</TD>
								<TD>{seller?.lastname}</TD>
								<TD>{seller?.username}</TD>
								<TD>{seller?.roles?.includes('Merchant') ? 
									<SellerRole>
										<h5>Seller</h5>
									</SellerRole>
									: null}
								</TD>
								<TD>
									<Main_Plain iconLeft="Edit" onClick={() => setSeller(seller)} />
								</TD>
							</TR>
						))}
					</tbody>
				</Table>
            </PageAlwaysVisible>
        </TeamPage>
    );
}