const convert_translations = (translations) => {

    var languageEN = {
        translations: {

        }
    }
    var languageEL = {
        translations: {

        }
    }
    var languageDE = {
        translations: {

        }
    }
    var languageIT = {
        translations: {

        }
    }

    translations.forEach((t) => {
        switch (t.lang){
            case ('el'):{
                languageEL.translations[t.key] = t.value;
                break;
            }
            case ('de'): {
                languageDE.translations[t.key] = t.value;
                break;
            }
            case ('en'): {
                languageEN.translations[t.key] = t.value;
                break;
            }
            case ('it'): {
                languageIT.translations[t.key] = t.value;
                break;
            }
        }
    })

    
    return {languageDE, languageEL, languageEN, languageIT}
}

export default convert_translations;