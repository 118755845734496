import { Dates_, IconSvg } from 'monica-alexandria';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';


const DateDropdown = styled.div`
  position: relative;
  width: 100%;
    cursor: pointer;
    appearance: none;
    outline: 0;
    padding: var(--normalPads);
    font-size: var(--normalText);
    border-radius: var(--normalRadius);
    padding-right: 4rem;
    color: ${(props) => props.theme.color};
    background: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.background};


  svg {
    position: absolute;
    display: block;
    z-index: 5;
    pointer-events: none;
    fill: ${(props) => props.theme.color};
    height: 0.8rem;
    top: 1.3rem;
    right: 1rem;
  }
`;

const DatesWrapper = styled.div`
    position: absolute;
    top: 5.5rem;
    left: 0;
    z-index: 100;
`


export const Date_Dropdown = (props) => {

    const [showDates, setShowDates] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState('bottom'); // New state to track position (above or below)
    const dateWrapperRef = useRef(null);
    const dropdownTriggerRef = useRef(null);
    const minDate = new Date();

    const toggleDropdown = (event) => {
        event.stopPropagation();
        setShowDates((prevShowDates) => !prevShowDates);
    };

    const handleChangeDate = (selection) => {
        props.onChangeDate(props.action.id, selection );
    }
    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dateWrapperRef.current &&
                !dateWrapperRef.current.contains(event.target) &&
                dropdownTriggerRef.current &&
                !dropdownTriggerRef.current.contains(event.target)
            ) {
                setShowDates(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (showDates && dropdownTriggerRef.current && dateWrapperRef.current) {
            const triggerRect = dropdownTriggerRef.current.getBoundingClientRect();
            const dropdownHeight = dateWrapperRef.current.offsetHeight;

            // Check available space below and above the trigger
            const spaceBelow = window.innerHeight - triggerRect.bottom;
            const spaceAbove = triggerRect.top;

            if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
                setDropdownPosition('top'); // Show above if not enough space below
            } else {
                setDropdownPosition('bottom'); // Default to showing below
            }
        }
    }, [showDates]);



    console.log('showDates', showDates);
  return (
    <DateDropdown
        ref={dropdownTriggerRef}
        onClick={toggleDropdown}
        >
    <p>{props.action.date ? props.action.date.toLocaleDateString("el-GR", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) : 'Select date'}</p>
    <IconSvg Icon='Dropdown' />
    {showDates ? (
        <DatesWrapper
            ref={dateWrapperRef}
            style={{
                position: 'absolute',
                top: dropdownPosition === 'bottom' ? '100%' : 'auto',
                bottom: dropdownPosition === 'top' ? '100%' : 'auto',
                zIndex: 1000, // Make sure the dropdown is above other elements
            }}
        >
            <Dates_
                allowPartialRange={false}
                onChange={handleChangeDate}
                value={props.action.date}
                selectRange={false}
                dateFullCellRender={(date) => date.date()}
                minDate={minDate}
            />
        </DatesWrapper>
    ) : null}
</DateDropdown>
  )
}
