import React from 'react'
import styled from 'styled-components'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const DateTimePicker = styled.div`



  input{
    width: 100%;
    border: none;
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.color};
    box-shadow: ${(props) => props.theme.in};
    border-radius: var(--smallRadius);
    transition-property: box-shadow;
    transition-duration: 0.5s;
    padding: var(--normalPads);
    font-size: var(--normalText);

    &::placeholder {
      color: inherit;
      opacity: 0.5;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }

    &:focus {
      outline: 0;
      box-shadow: ${(props) => props.theme.inFocus};
    }
  }


  .react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
  }

.react-datepicker {
  font-family: "fontMedium", sans-serif;
  font-size: var(--normalText);
  background-color:${(props) => props.theme.background};
  background: ${(props) => props.theme.background};
  color:${(props) => props.theme.color};
  border: 1px solid var(--grey);
  border-radius: var(--smallRadius);
  display: inline-block;
  position: relative;
  box-shadow: ${(p) => p.theme.out};
  border: none;

  // Reverting value set in .react-datepicker-popper
  line-height: initial;
}

.react-datepicker__month-container{
  width: 30rem;
}

.react-datepicker__day-name{

}

.react-datepicker h2{
  font-size: 1.5rem;
  color:${(props) => props.theme.color};
 
}

.react-datepicker__navigation {
  align-items: flex-end;
}


.react-datepicker__time {
  background: ${(props) => props.theme.background};
  color:${(props) => props.theme.color};
}

.react-datepicker__day {
  background: ${(props) => props.theme.background};
  color:${(props) => props.theme.color};
  width: 4rem;
  text-align: center;
  padding: 1rem 0 1rem 0;
  margin: 0;
}

.react-datepicker__time-list-item:hover,
.react-datepicker__day:hover {
  background: ${p => p.theme.low} !important;
  background-color: ${p => p.theme.low} !important;
}


.react-datepicker__header {
    background: ${(props) => props.theme.background};
    border-bottom: 1px solid var(--grey);
    overflow: hidden;
    border-top-left-radius: var(--smallRadius);
    border-top-right-radius: var(--smallRadius);
    padding: var(--normalPads);
    position: relative;

   
}
.react-datepicker__day-name {
  color: ${(props) => props.theme.color};
  font-size: 1.5rem;
  width: 4rem;
  margin: 0;
  padding: 0;
}
.react-datepicker-time__header{
    color: ${(props) => props.theme.color};
    font-size: 1.2rem;
}

  .react-datepicker__time-list{
    height: 27rem !important;
  }

  
  .react-datepicker__day-names {
    margin-bottom: 0px;
    padding-top: 1rem;
}

.react-datepicker__day--selected{
  background: var(--main);
  color: var(--white);
}

.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--selected:hover{
  background-color: var(--main) !important;
  background: var(--main) !important;
}

.react-datepicker__time-list-item--selected{
  background-color: var(--main) !important;
  background: var(--main) !important;
  color: var(--white);
}
`


export const Date_Time_Picker = (props) => {
  return (
    <DateTimePicker>
        <DatePicker
            selected={props.selected}
            onChange={(date) => props.updateAction(date)}
            showTimeSelect
            timeFormat="HH:mm"
            // minDate={new Date()}
            timeIntervals={5} // Set the time intervals to 5 minutes
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="Select date and time"
        />
    </DateTimePicker>
  )
}
