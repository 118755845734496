import { CardsFull, device, Grey_Link, Main_Plain, PageAlwaysVisible, PageFull, PageSixty, PageTitle, Preset_Card } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import store from '../../../redux/store';
import styled from 'styled-components';

let config = require('../../../config/index').config

const LinkWrapper = styled.div`
    background: ${p => p.theme.mid};
    border-radius: var(--smallRadius);
    padding: var(--largePads);
    gap: 1rem;

  //  padding: var(--smallPads);
    h3{
        padding-bottom: 1rem;
    }

`

const LinkText = styled.div`
  
   
    
`

const LinkClipboard = styled.div`
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    border-radius: var(--smallRadius);

    padding: var(--normalPads);
    background: ${(p) => p.theme.background};
    box-shadow: ${(p) => p.theme.in};

    
    @media ${device.md} {
        grid-template-columns: 1fr ;

        .MainPlain{
            display: flex;
            justify-content: right;
        }
    }


`
export default function SELLER(props) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {lang} = useParams();

    const fahedURL = config.FAHED_FRONTEND_URL;
    const user = store.getState()?.user?.user;
    console.log('userId', user);
    
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${fahedURL}/en/questad-interest/${store.getState()?.user?.user?.sub}`);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 4000);
    };

    
    return (
        <PageSixty>
            <PageAlwaysVisible>
            <PageTitle>
                <Grey_Link iconLeft='Back' onClick={()=> navigate(`/${lang}/`)}/> 
                <h1>{t("Seller Card")}</h1>
            </PageTitle>
                <LinkWrapper>
                    <h3>{user?.firstname}'s seller url</h3>
                    <LinkClipboard onClick={handleCopy}>
                        <h4>{`${fahedURL}/en/questad-interest/${store.getState()?.user?.user?.sub}`}</h4>
                        {isCopied ?   <Main_Plain iconLeft= 'Check'  text={'Copied'}  /> : <Grey_Link text='Copy' iconLeft='Default' onClick={handleCopy}/> }
                    </LinkClipboard>
                  
                </LinkWrapper>
              
            </PageAlwaysVisible>
        </PageSixty>
    )  
}