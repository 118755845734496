import { Builder_, Checkbox_, Dates_, Input_ } from 'monica-alexandria';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const LeadsFilters = styled.div``

export default function Leads_Filters(props) {

    const {t} = useTranslation();

    console.log('props.checkedCustomerLost', props.sources, props.sellers);
    
    const onChange = (selections) => {
        props.onDates(selections)
    };

    console.log('dateFilters', props.dateFilters);
    

  return (
    <LeadsFilters>
        <Builder_ title={t("Show only specific period")}>
            <Dates_ 
                allowPartialRange={true}
                onChange={onChange} 
                value={props?.valueDates} 
                selectRange={true} 
                dateFullCellRender={(date) => date?.date()} 
                maxDate={props.maxDates}
            />
        </Builder_>
        <Builder_ title={t("Show only specific sources")}>
            <Checkbox_
                text="Questad"
                value="Questad"
                onClick={()=>props.onSource('Questad')}
                checked={props.checkedQuestad}
            />
            <Checkbox_
                text="Questad form"
                value="Questad form"
                onClick={()=>props.onSource('Questad form')}
                checked={props.checkedQuestadForm}
            />
            <Checkbox_
                text="AdSnap form"
                value="AdSnap form"
                onClick={()=>props.onSource('AdSnap form')}
                checked={props.checkedAdSnapForm}
            />
            <Checkbox_
                text="Static"
                value="Static"
                onClick={()=>props.onSource('Static')}
                checked={props.checkedStatic}
            />
            {props.sources?.map((source, index) => (
                <>
                <Checkbox_ 
                    key={index} 
                    text={source} 
                    value={source}
                    onClick={() => props.onSource(source)}
                    checked={props.checkedSources(source)} 
                />
                </>
            ))}
        </Builder_>
        <Builder_ title={t("Show only leads assigned to")}>
            {props.sellers?.map((seller, index) => (
                <>
                {/* {console.log(' props.checkedSellers(seller?.username)',  props.checkedSellers(seller?.username))} */}
                
                <Checkbox_ 
                    key={index} 
                    text={seller?.username} 
                    onClick={()=>props.onSellers(seller?.username)}
                    checked={props.filters.assign?.includes(seller?.username)}
                    id={seller?.username}
                />
                </>
            ))}
        </Builder_>
        <Builder_ title={t("Show only leads with the following automated tags")}>
            <Checkbox_
                text="Unregistered"
                onClick={()=>props.onAutomated('Unregistered')}
                checked={props.checkedUnregistered}
            />
            <Checkbox_
                text="Registered"
                onClick={()=>props.onAutomated('Registered')}
                checked={props.checkedRegistered}
            />
            <Checkbox_
                text="Paid Questad"
                onClick={()=>props.onAutomated('Paid Questad')}
                checked={props.checkedPaidQuestad}
            />
            <Checkbox_
                text="Lost Questad"
                value="Lost Questad"
                onClick={()=>props.onAutomated('Lost Questad')}
                checked={props.checkedLostQuestad}
            />
        </Builder_>
        <Builder_ title={t("Show leads with specific status")}>
            <Checkbox_
                text="Open"
                onClick={()=>props.onStatus('Open')}
                checked={props.checkedOpen}
            />
            <Checkbox_
                text="Answered"
                onClick={()=>props.onStatus('Answered')}
                checked={props.checkedAnswered}
            />
            <Checkbox_
                text="Not Answered"
                onClick={()=>props.onStatus('Not Answered')}
                checked={props.checkedNotAnswered}
            />
            <Checkbox_
                text="Meeting Set"
                onClick={()=>props.onStatus('Meeting Set')}
                checked={props.checkedMeetingSet}
            />
             <Checkbox_
                text="Upsell"
                onClick={()=>props.onStatus('Upsell')}
                checked={props.checkedUpsell}
            />
            <Checkbox_
                text="Customer Won"
                value="Customer Won"
                onClick={()=>props.onStatus('Customer Won')}
                checked={props.checkedCustomerWon}
            />
            <Checkbox_
                text="Customer Lost"
                id="Customer Lost"
                onClick={()=>props.onStatus('Customer Lost')}
                checked={props.checkedCustomerLost}
            />
        </Builder_>	
    </LeadsFilters>
  )
}