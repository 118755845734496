import { Builder_, Dropdown_, Elastic_Search, Input_, Textarea_, Red_, Main_Plain } from 'monica-alexandria';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Date_Dropdown } from './Date_Dropdown';
import store from '../../../../redux/store';
import { Leads_Actions } from './Leads_Actions';

const LeadsAdd = styled.div``

const LeadsEditSplit = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

export default function Leads_Add(props) {

    const {t} = useTranslation();
	const [addNote, setAddNote] = useState(false)

	const [phoneInput, setPhoneInput] = useState('')
	const [mailInput, setMailInput] = useState('')

	const loggedUser = store.getState()?.user?.user;

    const onAddToList = (input, field, setInputValue) => {
        if (input?.trim()) {
            const newPhone = input?.trim();
            props.setNewLead({ 
                ...props.newLead, 
                [field]: [...(props.newLead[field] || []), input?.trim()] // Append new phone to the list
            });
            setInputValue(''); 
        }
    };

    const onDeleteField = (index, fieldName) => {
        const updatedValues = props.newLead[fieldName].filter((_, i) => i !== index);
        props.setNewLead({ 
            ...props.newLead, 
            [fieldName]: updatedValues // Set updated list for the specified field
        });
    };


    console.log('ACTIONS HELP', props.actions);
    console.log('ADD HELP', props.newLead);

	
  return (
    <LeadsAdd>
		<LeadsEditSplit>
			<Builder_ title={t("Enter lead's first name")}>
				<Input_
					placeholder={t("First name")}
					value={props.newLead?.firstname}
					onChange={(e)=>props.setNewLead({ ...props.newLead, firstname: e.target.value})}
				/>
			</Builder_>
			<Builder_ title={t("Enter lead's last name")}>
				<Input_
					placeholder={t("Last name")}
					value={props.newLead?.lastname}
					onChange={(e)=>props.setNewLead({ ...props.newLead, lastname: e.target.value})}
				/>
			</Builder_>
		</LeadsEditSplit>
		
		<Builder_ title={t("Enter lead's phone numbers")}>
			<Input_
				placeholder={t("Phone number")}
				list={props.newLead?.phoneList || []}
				icon_ListItem="Phone"
				onChange={(e)=> setPhoneInput(e.target.value)}
				value={phoneInput} // Display phone numbers as comma-separated values
				onDelete={(index)=> onDeleteField(index, 'phone')}
				onAdd={()=>onAddToList(phoneInput,'phoneList',setPhoneInput)}
				add={t('Add')}
			/>
		</Builder_>
        <Builder_ title={t("Enter lead's emails")}>
			<Input_
				placeholder={t("Email")}
				list={props.newLead?.emailList || []}
				icon_ListItem="Email"
				onChange={(e)=> setMailInput(e.target.value)}
				value={mailInput} // Display phone numbers as comma-separated values
				onDelete={(index)=>onDeleteField(index, 'email')}
				onAdd={()=>onAddToList(mailInput,'emailList',setMailInput)}
				add={t('Add')}
			/>
     
		</Builder_>
            
		<LeadsEditSplit>	
            <Builder_ title={t("Enter lead's industry")}>
                <Input_
                    placeholder={t("Industry")}
                    value={props.newLead?.industry}
                    onChange={(e) => props.setNewLead({ ...props.newLead, industry: e.target.value})}
                />
            </Builder_>	
            <Builder_ title={t("Enter lead's location")}>
                <Input_
                    placeholder={t("Location")}
                    value={props.newLead?.location}
                    onChange={(e) => props.setNewLead({ ...props.newLead, location: e.target.value })}
                />
            </Builder_>	
        </LeadsEditSplit>

		<Builder_ title={t("Assign seller")}>
			<Dropdown_>
				<select 
					//value={props.user?.id || 'none'} 
					value={props.newLead?.assignee?.id || 'none'} 
					onChange={(e) => {
						const selectedSeller = props.fileAssignee?.find(seller => seller.id === e.target.value);
						
						if (selectedSeller) {
							props.setNewLead({ ...props.newLead, assignee: selectedSeller})
						}
					}}
				>
					<option value={'none'} disabled>{t('Seller')}</option>
					{props.fileAssignee?.map((seller) => (
						<option
							key={seller.id}
							value={seller.id}
						>
							{seller.username}
						</option>
					))}
				</select>
			</Dropdown_>			
		</Builder_>
        <Leads_Actions 
            actions={props.actions} 
            setActions={props.setActions}
        />
		
    </LeadsAdd>
  )
}
