import { Builder_, Main_Plain, Red_ } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'
// import { Main_Plain, Red_ } from '../button'
// import { Builder_, Textarea_ } from '../form'

const Notes = styled.div`
  height: 100%;
  background: ${p => p.theme.low};
`

const NotesTitle = styled.h4`
  margin-bottom: 1rem;
`

const NotesContainer = styled.div``

const NotesWrapper = styled.div``

const NotesList = styled.ul`
  display: flex;
  flex-direction: column-reverse;
`

const NotesItem = styled.li`
  margin-top: 1rem;
  padding: 1rem;
  background: ${p => p.theme.background};

  h6{
    padding: .5rem 1rem;
    border-radius: 1rem;
    background: ${p => p.theme.low};
    display: inline-block;
    margin-bottom: 1rem;
  }

  h5{
    margin-left: .5rem;
  }

  p{
    margin-top: 1rem;
    text-align: right;
  }
`

const NotesItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

export const Notes_ = (props) => {
  const { notes = [], user = 'user', text = 'text', date = 'date', ...rest } = props;
  return (
    <Notes>
      {props.input}
      <NotesContainer>
        {props.editing ?
          <Builder_>
            {props.edit}
          </Builder_>
        :
          <NotesWrapper>
            <NotesList>
              {notes?.map((note, i) =>
                <NotesItem key={i}>
                  {props.actions ? 
                    <NotesItemActions>
                      <Main_Plain noPadding iconLeft="Edit" onClick={() => props.onEdit(note)} />
                      {props.delete ? 
                        <Red_ noPadding iconLeft="Delete" onClick={() => props.onDelete(note)} />
                      : 
                        null
                      }
                    </NotesItemActions>
                  :
                    null
                  }
                  <h6>{note[user]}</h6>
                  <h5>{note[text]}</h5>
                  <p>{note[date]}</p>
                </NotesItem>
              )}
            </NotesList>
          </NotesWrapper>
        }
      </NotesContainer>
    </Notes>
  )
}
