import axios from "axios"
import { config } from "../../config"

const get_leads = (pageNo, pageSize) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/getLeads`, {
            pageNo: pageNo, 
            pageSize: pageSize
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(error => {
            console.error('There was an error fetching leads!', error);           
            return reject(error)
        });
    })
}



const get_lead_history = (leadId) => {
    console.log('HEREE', leadId);
    
    return new Promise((resolve, reject) => {
        axios.get(`${config.NIFFLER_BACKEND_URL}/leads/getLeadHistory?leadId=${leadId}`)
        .then(res => {
            resolve(res?.data?.lead);
        })
        .catch(error => {
            console.error('There was an error fetching lead!', error);           
            return reject(error)
        });
    })
}


const get_lead = (lead) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.NIFFLER_BACKEND_URL}/leads/getLead`, {
            params: {
                email: lead?.email
            }
        })
        .then(res => {
            resolve(res.data?.lead);
        })
        .catch(error => {
            console.error('There was an error fetching lead!', error);           
            return reject(error)
        });
    })
}

const add_tag = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/customTag`, {
            data: {
                email: lead?.email,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                userId: lead?.userId
            }, 
            customTag: {
                name: lead?.customTag, 
                type:'custom'
            }             
    
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const reset_tag = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/resetTag`, {
            data: {
                email: lead?.email,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                userId: lead?.userId
            }, 
            customTag: {
                name: lead?.customTag, 
                type:'open'
            }             
    
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const add_lead = (lead, actions) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/addLead`, {
            data: {
                email: lead?.email,
                emailList: lead?.emailList,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                phone: lead?.phone,
                phoneList: lead?.phoneList,
                industry: lead?.industry,
                location: lead?.location
            }, 
            sourceTag: {
                name: 'Manual Add', 
                type:'source'
            },
            customTag: {
                name: lead?.customTag, 
                type:'custom'
            },
            autoTag: {
                name: 'Unregistered', 
                type:'auto'
            },
            seller: lead?.assignee,
            actions: actions,
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}



const add_note = (lead, note) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/addNote`, {
            data: {
                email: lead?.email,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                userId: lead?.userId
            }, 
            note: lead?.note          
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


const edit_notes = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/editNotes`, {
            data: {
                userId: lead?.userId,
                email: lead?.email,
                _id: lead?._id
            }, 
            notes: lead?.notes       
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const search_lead = (searchData) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.NIFFLER_BACKEND_URL}/leads/searchLead`, {
            params: {
                search: searchData
            }
        })
        .then(res => {
            resolve(res.data?.results);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const search_seller = (sellers, searchData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/searchSeller`, {
            sellers: sellers,
            search: searchData
        })
        .then(res => {
            resolve(res.data?.results);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const add_seller = (seller, status) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/addSeller`, {
            seller: seller.id,
            status: status
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


const search_tags = (filters, date, setLeads, setLoadingFilters) => {

    console.log('filters', filters);
    return new Promise(async(resolve, reject) => {
        
        let res;
        let leadsFetched = [];
      
        // This is wrong but it supports up to a million leads
        // If you need to change this line then we have become millionaires :)
    

        for (let i=0; i<100000; i++) {
            res = await axios.post(`${config.NIFFLER_BACKEND_URL}/leads/searchTags`, {
            source: filters?.source, 
            auto: filters?.auto, 
            custom: filters?.custom,
            startDate: date?.startDate, 
            endDate: date?.endDate,
            assign: filters?.assign,
            pageNo: i+1

            }).catch(error => {
                console.error('Error: ', error)
                return reject(error)
            })
            leadsFetched = [...leadsFetched, ...res?.data?.result?.leads]
            setLoadingFilters(true)
            if (leadsFetched)  

            console.log('leadsFetched iteration', i, '| length = ', res?.data?.result?.leads?.length)
            console.log('next = ', res?.data?.result?.next)
            setLeads(leadsFetched)
            if (!res?.data?.result?.next) return resolve(leadsFetched);
        }
        resolve(leadsFetched);
        
    })
}

const get_sellers = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.NIFFLER_BACKEND_URL}/leads/getUsersWithRoles`)
        .then(res => {
            resolve(res.data?.users);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const merge_leads = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/mergeLeads`, {
            emailA: lead?.email,
            emailB: lead?.mergeLead
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


const delete_lead = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/deleteLead`, {
            lead: lead
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


const get_users = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.NIFFLER_BACKEND_URL}/leads/getUsers`)
        .then(res => {
            resolve(res.data?.users);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const assign_seller = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/assignSeller`, {
            data: {
                email: lead?.email,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                userId: lead?.userId
            }, 
            seller: lead?.seller         
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const edit_lead = (lead) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/editLead`, {
            data: {
                _id: lead?._id,
                email: lead?.email,
                emailList: lead?.emailList,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                phone: lead?.phone,
                phoneList: lead?.phoneList,
                industry: lead?.industry,
                location: lead?.location
            }
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


const create_action = (action) => {
    return new Promise((resolve, reject) => {
    // for (let i=0; i<actions?.lenght; i++) {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/createAction`, {
            data: {
                leadId: action?.leadId,
                seller: action?.seller,
                action: action?.action,
                schedule: action?.schedule,
                comment: action?.comment
            }
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    // }
    })
}

const delete_action = (actionIds, leadId) => {
    console.log('actionIds', actionIds);
    
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/leads/deleteAction`, {
            actionIds: actionIds,
            leadId: leadId
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}

const get_sources = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.NIFFLER_BACKEND_URL}/leads/getSources`)
        .then(res => {
            resolve(res.data?.sources);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


export {
    get_leads,
    get_lead,
    add_tag, 
    add_note,
    edit_notes,
    add_lead,
    search_lead,
    search_tags,
    get_sellers,
    assign_seller,
    add_seller,
    get_users,
    delete_lead,
    search_seller,
    edit_lead,
    reset_tag,
    merge_leads,
    get_lead_history,
    create_action,
    delete_action,
    get_sources
};