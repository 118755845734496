export const config = {
        BACKEND_URL: process.env.REACT_APP_BACKEND_PROTOCOL +
                        process.env.REACT_APP_BACKEND_HOST +
                        process.env.REACT_APP_BACKEND_PORT,
        KEYMAKER: process.env.REACT_APP_KEYMAKER_URL_PROTOCOL +
                        process.env.REACT_APP_KEYMAKER_URL_HOST +
                        process.env.REACT_APP_KEYMAKER_URL_PORT,
        GRIPHOOK_BACKEND_URL: process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_BACKEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_BACKEND_PORT,
        ERISED_BACKEND_URL: process.env.REACT_APP_ERISED_BACKEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_BACKEND_HOST +
                        process.env.REACT_APP_ERISED_BACKEND_PORT,
        BOILER_BACKEND_URL: process.env.REACT_APP_BOILER_BACKEND_PROTOCOL +
                        process.env.REACT_APP_BOILER_BACKEND_HOST +
                        process.env.REACT_APP_BOILER_BACKEND_PORT,
        erised: process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_FRONTEND_HOST +
                        process.env.REACT_APP_ERISED_FRONTEND_PORT,
        minio:  process.env.REACT_APP_MINIO_PROTOCOL +
                        process.env.REACT_APP_MINIO_HOST,
        griphook: process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT,
        akihiko: process.env.REACT_APP_AKIHIKO_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_HOST +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_PORT,
        boiler: process.env.REACT_APP_BOILER_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_BOILER_FRONTEND_HOST +
                        process.env.REACT_APP_BOILER_FRONTEND_PORT,
        NIFFLER_BACKEND_URL: process.env.REACT_APP_NIFFLER_BACKEND_PROTOCOL +
                        process.env.REACT_APP_NIFFLER_BACKEND_HOST +
                        process.env.REACT_APP_NIFFLER_BACKEND_PORT,
        niffler: process.env.REACT_APP_NIFFLER_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_NIFFLER_FRONTEND_HOST +
                        process.env.REACT_APP_NIFFLER_FRONTEND_PORT,
        FAHED_FRONTEND_URL: process.env.REACT_APP_FAHED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_FAHED_FRONTEND_HOST +
                        process.env.REACT_APP_FAHED_FRONTEND_PORT,
      }
      



