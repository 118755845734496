
import axios from "axios"
import { config } from "../../config"

const assign_seller = (lead, seller) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.NIFFLER_BACKEND_URL}/history/assignSeller`, {
            data: {
                _id: lead?._id,
                email: lead?.email,
                firstname: lead?.firstname,
                lastname: lead?.lastname,
                userId: lead?.userId
            }, 
            seller: seller         
        })
        .then(res => {
            resolve(res);
        })
        .catch(error => {
            console.error('Error: ', error)
            return reject(error)
        })
    })
}


export {
   assign_seller
}