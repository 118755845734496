import { Input_, Builder_, Confirmation_, Dropdown_, Elastic_Search, Main_Plain, Red_, Textarea_, Dates_, IconSvg } from 'monica-alexandria'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getUserInfoOfAssignedSeller } from '../utils'
import { Notes_ } from './Notes_'
import store from '../../../../redux/store'
import { Date_Dropdown } from './Date_Dropdown'
import { Leads_Actions } from './Leads_Actions'

const LeadsEdit = styled.div`

`


const LeadsEditSplit = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

export const Leads_Edit = ({selectedLead, setSelectedLead, sellers,user,setUser, handleDeleteLead, leads, actions, setActions, actionsDB, setActionsDB, setActionsToDlt}) => {

    const {t} = useTranslation();
	const [noteToEdit, setNoteToEdit] = useState(null);
    const [leadToDelete, setLeadToDelete] = useState(false)
    const [noteToDelete, setNoteToDelete] = useState(false)
    const [addNote, setAddNote] = useState(false)

    // const [actions, setActions] =useState([])

	const [phoneInput, setPhoneInput] = useState('')
	const [mailInput, setMailInput] = useState('')
    
    const loggedUser = store.getState()?.user?.user;

    
    // const handleAddAction = () => {
    //     setActions([...actions, {
    //         id: Date.now(), // Unique ID for each action
    //         action: 'Note',
    //         date: null,
    //         hours: '',
    //         minutes: '',
    //         note: '',
    //         created: Date.now(),
    //         seller: loggedUser?.username
    //     }]);
    // };

    
        // Function to update an action in the list
        const updateAction = (id, updatedAction) => {
            console.log('id', id, updateAction);
            
            setActions(actions.map(action => action.id === id ? { ...action, ...updatedAction } : action));
        };


        const onAddToList = (input, field, setInputValue) => {
            if (input?.trim()) {
                const newPhone = input?.trim();
                setSelectedLead({ 
                    ...selectedLead, 
                    [field]: [...(selectedLead[field] || []), input?.trim()] // Append new phone to the list
                });
                setInputValue(''); 
            }
        };
    
        const onDeleteField = (index, fieldName) => {
            const updatedValues = selectedLead[fieldName].filter((_, i) => i !== index);
            setSelectedLead({ 
                ...selectedLead, 
                [fieldName]: updatedValues // Set updated list for the specified field
            });
        };

	

    const handleDelete = () => {
        handleDeleteLead(leadToDelete)
        setLeadToDelete(false)
    }


    useEffect(() => {

       
    }, []);
    console.log('actions!!', actions);    
    console.log('selectedLead!!', selectedLead);    
   
  return (
    <LeadsEdit>
        {leadToDelete !== false ? 
            <Confirmation_
                message={t("Are you sure you want to delete this lead?") }
                no={t("No")}
                onNo={ () => setLeadToDelete(false) }
                onYes= {()=> handleDelete()}
                yes={t("Yes")}
        /> : null }
        {/* <LeadActions>
            <Red_ noPadding text={t('Delete lead')} iconLeft="Delete" onClick={() => setLeadToDelete(selectedLead)} />
        </LeadActions> */}
        <LeadsEditSplit>
            <Builder_ title={t("Enter lead's first name")}>
                <Input_
                    placeholder={t("First name")}
                    value={selectedLead?.firstname}
                    onChange={(e) => setSelectedLead({ ...selectedLead, firstname: e.target.value, edit: true })}
                />
            </Builder_>
            <Builder_ title={t("Enter lead's last name")}>
                <Input_
                    placeholder={t("Last name")}
                    value={selectedLead?.lastname}
                    onChange={(e) => setSelectedLead({ ...selectedLead, lastname: e.target.value, edit: true })}
                />
            </Builder_>
        </LeadsEditSplit>
       
        <Builder_ title={t("Enter lead's phone number")}>
            <Input_
				placeholder={t("Phone number")}
				list={selectedLead?.phoneList || []}
                defaultListItem={selectedLead?.phone?.length>0 ? [selectedLead?.phone] : null}
				icon_ListItem="Phone"
				onChange={(e)=> setPhoneInput(e.target.value)}
				value={phoneInput} 
				onDelete={(index)=> onDeleteField(index, 'phone')}
				onAdd={()=>onAddToList(phoneInput,'phoneList',setPhoneInput)}
				add={t('Add')}
			/>
        </Builder_>	
        <Builder_ title={t("Enter lead's emails")}>
			<Input_
				placeholder={t("Email")}
				list={selectedLead?.emailList || []}
                defaultListItem={selectedLead?.email?.length>0  ? [selectedLead?.email] : null}
				icon_ListItem="Email"
				onChange={(e)=> setMailInput(e.target.value)}
				value={mailInput} 
				onDelete={(index)=>onDeleteField(index, 'email')}
				onAdd={()=>onAddToList(mailInput,'emailList',setMailInput)}
				add={t('Add')}
			/>
		</Builder_>	
        <LeadsEditSplit>	
            <Builder_ title={t("Enter lead's industry")}>
                <Input_
                    placeholder={t("Industry")}
                    value={selectedLead?.industry}
                    onChange={(e) => setSelectedLead({ ...selectedLead, industry: e.target.value, edit: true })}
                />
            </Builder_>	
            <Builder_ title={t("Enter lead's location")}>
                <Input_
                    placeholder={t("Location")}
                    value={selectedLead?.location}
                    onChange={(e) => setSelectedLead({ ...selectedLead, location: e.target.value, edit: true })}
                />
            </Builder_>	
        </LeadsEditSplit>
        <Builder_ title="Assign Seller">
            <Dropdown_>
                <select 
                    value={user?.id || 'none'} 
                    onChange={(e) => {
                        const selectedSeller = sellers.find(seller => seller.id === e.target.value);
                        if (selectedSeller) {
                            setUser(selectedSeller);
                        }
                    }}
                >
                    <option value={'none'} disabled>{t('Seller')}</option>
                    {sellers?.map((seller) => (
                        <option
                            key={seller.id}
                            value={seller.id}
                        >
                            {seller.username}
                        </option>
                    ))}
                </select>
            </Dropdown_>
        </Builder_>

        <Builder_ title={t("Merge with another lead")}>
            <Elastic_Search
                debounce={750}
                displayProperty="email"
                file={leads}
                maximumSuggestions={7}
                placeholder="Email address of lead"
                results={(data) => setSelectedLead({ ...selectedLead, mergeLead: data?.email, edit: true })}
                returnType="object"
                searchKeys={[
                    'email'
                ]}
                selectionType="one"
            /> 
        </Builder_>
        <Leads_Actions
            leadId={selectedLead?._id}
            actions={actions} 
            actionsDB={actionsDB} 
            setActions={setActions}
            setActionsDB={setActionsDB}
            setActionsToDlt={setActionsToDlt}
        />
    </LeadsEdit>
  )
}
