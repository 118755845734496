import { CardsFull, On_Click_Card, PageFull, PageTitle, PageTools, ThemeContext, logo } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { checkPermissions } from '../../../helpers/checkPermissions'

let config = require('../../../config/index').config

export default function PANEL(props) {

  const {t} = useTranslation()
  const {theme} = useContext(ThemeContext);

  const nifflerURL = config.niffler;

  return (
    <PageFull>
        <PageTitle>
          <h1>{t("Admin Panel")}</h1>
        </PageTitle>
        <CardsFull>
          <On_Click_Card onClick={() => window.location.assign(`${nifflerURL}/en/leads`)} imgSmall="" title="Prospect Leads" />

          {checkPermissions('Baron') ? 
            <>
              <On_Click_Card onClick={() => window.location.assign(`${nifflerURL}/en/team`)} imgSmall="" title="Team" />
            </>
          : null}

          {checkPermissions('Merchant') ? 
            <>
              <On_Click_Card onClick={() => window.location.assign(`${nifflerURL}/en/seller`)} imgSmall="" title="Seller's card" />
            </>
          : null}
          
          
        </CardsFull>
    </PageFull>
  )
}